import { auth, initializeApp, firestore, storage } from "firebase";
import { config } from './config';

initializeApp(config);

export default {
    database: firestore(),
    store: storage(),
    googleProvider: new auth.GoogleAuthProvider(),
    fbProvider: new auth.FacebookAuthProvider()
};
